<template>
  <div class="chain-details">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/chain/list' }">机构列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{breadcrumb}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="chainForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="机构名称" prop="chain_name">
                <el-input class="form-input" size="small" v-model="chainForm.chain_name"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="平台名称" prop="title">
                <el-input class="form-input" size="small" v-model="chainForm.title"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-pic-title">机构logo</div>
          <div class="form-pic-desc">
            <el-form-item prop="pic">
              <el-image v-if="showImg" class="pic-img" :src="chainForm.pic"></el-image>
            </el-form-item>
            <div class="avatar-uploader">
              <i class="el-icon-plus avatar-uploader-icon" @click="addPic"></i>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <el-form-item label="选择商品">
            <el-transfer v-model="chainForm.invert_goods" :data="goodsData" :titles="['全部商品', '不显示的商品']"></el-transfer>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <MyDialog :dialogVisible="dialogVisible" @changeDialog="changeDialog" @getPic="getPic" :limit="limit" />
  </div>
</template>

<script>
import MyDialog from '../../../components/MyDialog.vue'
import { addChain, getChainDetails, shopGoodsList } from '../../../request/api'
import { mapState } from 'vuex'

export default {
  components: {
    MyDialog
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  data () {
    return {
      chainForm: {
        chain_name: '',
        pic: '',
        shop_chain_id: '',
        title: '',
        invert_goods: []
      },
      rules: {
        chain_name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' }
        ]
      },
      showImg: false,
      dialogVisible: false,
      limit: 0,
      breadcrumb: '新增机构',
      goodsData: [],
      query_info: {
        chain_id: '',
        limit: 999,
        page: 1
      }
    }
  },
  methods: {
    // 上传机构logo 限制1张
    addPic () {
      this.limit = 1
      this.dialogVisible = true
    },
    // 确认提交按钮
    async submitForm () {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (this.chainForm.chain_name === '') {
        this.$message.warning('请完善字段信息')
        return
      }
      const res = await addChain(this.chainForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('添加成功')
      this.$router.push({ path: '/chain/list' })
    },
    // 返回
    resetForm () {
      this.$router.back()
    },
    // 取消按钮 关闭弹窗
    changeDialog () {
      this.dialogVisible = false
    },
    // 组件保存图片的回调
    getPic (picArr, id) {
      this.dialogVisible = false
      picArr.forEach((pic) => {
        if (id === 1) {
          this.chainForm.pic = pic
          this.showImg = true
        }
      })
    },
    // 初始化操作
    async init () {
      // 初始化全部商品
      const res = await shopGoodsList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, '')
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.goodsData = res.data.data.goodsList.map(element => {
        return {
          'key': element.shop_goods_id,
          'label': element.name
        }
      })

      if (this.chainForm.shop_chain_id !== undefined) {
        this.breadcrumb = '编辑机构'
        const res = await getChainDetails(this.chainForm.shop_chain_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        let apiData = res.data.data
        this.chainForm.chain_name = apiData.chain_name
        this.chainForm.pic = apiData.pic
        this.chainForm.title = apiData.title
        this.chainForm.invert_goods = apiData.invert_goods.map(element => {
          return parseInt(element)
        })
        this.showImg = true
      }
    }
  },
  created () {
    this.chainForm.shop_chain_id = this.$route.query.shop_chain_id
    this.query_info.chain_id = this.loginData.chain_id
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.chain-details {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 340px;
            .form-input {
              width: 242px;
            }
          }
        }
        .form-pic-title {
          border-bottom-style: solid;
          border-bottom-color: #c4c4c4;
          border-bottom-width: 2px;
          width: 646px;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 400;
        }
        .form-pic-desc {
          display: flex;
          align-items: center;
          .avatar-uploader {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 120px;
              height: 120px;
              line-height: 120px;
              text-align: center;
            }
          }
          .avatar-uploader:hover {
            border-color: #409eff;
          }
          .pic-img {
            width: 120px;
            height: 120px;
            margin-right: 10px;
          }
        }
      }
      .form-bottom {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-top: 10px;
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
  }
}
</style>